// index Hero
// ==========================
function HeroFade() {
	const home = document.querySelector('#home');
	const header = document.querySelector('#home .js-header');
	const hero = document.querySelector('.js-index-hero');
	const fadeItem = document.querySelectorAll('.js-hero-fadein');
	if (home) {
		window.addEventListener('load', () => {
			home.classList.add('is-animation');
			setTimeout(() => {
				header.classList.add('is-hidden');
				hero.classList.add('is-hidden');
				fadeItem.forEach(element => {
					element.classList.add('is-hidden');
				});
			}, 500);
			setTimeout(() => {
				header.classList.add('is-visible');
				hero.classList.add('is-visible');
				fadeItem.forEach(element => {
					element.classList.add('is-visible');
				});
			}, 1000);
			setTimeout(() => {
				home.classList.remove('is-animation');
				header.classList.remove('is-hidden' ,'is-visible');
				hero.classList.remove('is-hidden' ,'is-visible');
				fadeItem.forEach(element => {
					element.classList.remove('is-hidden' ,'is-visible');
				});
			}, 5000);
		});
	}
}

export{ HeroFade };